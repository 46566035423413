let speed;

function makeNewPosition(container) {

    // Get viewport dimensions (remove the dimension of the div)
    let h = container.height() - 50;
    let w = container.width() - 50;

    let nh = Math.floor(Math.random() * h) - 150;
    let nw = Math.floor(Math.random() * w) + 150;

    return [nh, nw];

}

export function animateDiv(target) {
    let newq = makeNewPosition(target.parent().parent());
    let oldq = target.offset();
    let speed = calcSpeed([oldq.top, oldq.left], newq);

    target.animate({
        top: newq[0],
        left: newq[1]
    }, speed, function () {
        animateDiv(target);
    });

}

function calcSpeed(prev, next) {

    let x = Math.abs(prev[1] - next[1]);
    let y = Math.abs(prev[0] - next[0]);

    let greatest = x > y ? x : y;

    let speedModifier = 0.1;

    speed = Math.ceil(greatest / speedModifier);

    return speed;

}