import $ from "jquery";
import {animateDiv} from "./animationFunctions";
import "../../styles/first_page.scss";

$(document).ready(async function () {
    const imgLink = document.querySelector('#bed-storizz'),
        z = $('.z'),
        linkButton = document.querySelector('#start-here');
    // console.log(imgLink);
    imgLink.addEventListener('mouseover', function () {
        imgLink.classList.add('vibrate');
        z.removeClass('hidez');
    });
    imgLink.addEventListener('mouseout', function () {
        imgLink.classList.remove('vibrate');
        z.addClass('hidez');
    });
    linkButton.addEventListener('click', function () {
        let url = window.location.href.split("?")[0] += "configurator/";
        let params = new URLSearchParams(window.location.search);
        let paramsString = params.toString();
        if (paramsString) {
            url = url + "?" + paramsString;
        }
        window.location.href = url;
    });
    imgLink.addEventListener('click', function () {
        let url = window.location.href.split("?")[0] += "configurator/";
        let params = new URLSearchParams(window.location.search);
        let paramsString = params.toString();
        if (paramsString) {
            url = url + "?" + paramsString;
        }
        window.location.href = url;
    });
    for (let i = 1; i < 7; i++) {
        animateDiv($(`#image-${i}`));
    }

});